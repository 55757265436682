import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo,
  faClose
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Card, Table, Col, Row, Dropdown, Modal, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import DatePicker from 'components/base/DatePicker';
import { useCookies } from 'react-cookie';
import toast from 'react-hot-toast';
import { suspend } from 'suspend-react'
import Avatar from 'components/base/Avatar';
const { Image } = require('image-js');

let ItemBtn0 = require('assets/img/icons/items/ItemBtn0.png');

enum IconType {
  Item = 1,
  Skill,
}

let isInitIcons = false;
let icons = [];

async function getIcon(type: IconType, id: number, row: number, col: number) {

  while (!isInitIcons && (icons[id] == undefined || icons[id][row] == undefined || icons[id][row][col] == undefined)) {
    await new Promise(r => setTimeout(r, 200));
  }

  return icons[id][row][col] == undefined ? icons[0][0][0] : icons[id][row][col];
}

async function initIcons() {

  for (let id = 0; ; id++) {
    let file = null;

    try {
     file = require(`assets/img/icons/items/ItemBtn${id}.png`);
    } catch (err) {
     break;
    }

    let image = await Image.load(file);

    icons[id] = [];

    for (let row = 0; row < image.height / 32; row++) {
      icons[id][row] = [];

      for (let col = 0; col < image.width / 32; col++) {
        icons[id][row][col] = image.crop({ x: col * 32 + 2, y: row * 32 + 2, width: 28, height: 28 }).toDataURL();
      }
    }
  }  

  isInitIcons = true;
}

initIcons();

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var weekOfYear = require('dayjs/plugin/weekOfYear')
var isBetween = require('dayjs/plugin/isBetween')
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(weekOfYear)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

dayjs.tz.setDefault('Europe/Moscow');

const promoCodeStatsTablecolumns: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_ctname',
    header: 'Название',
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    id: 'items',
    cell: ({ row: { original } }) => {
      const { items } = original;

      return (
        <>
        {items.length > 0  ? (
          <Avatar.Group total={items.length} size="s">
            {items.map(item => (
              <Avatar key={item.a_index} size="s" src={item.img} />
            ))}
          </Avatar.Group>
        ) : (
          <Spinner animation="border" size="sm" />
        )}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: 80 } },
      //cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    id: 'date',
    header: 'Перв. - послед. дата покупки',
    accessorFn: row => row.dateEnd,
    cell: ({ row: { original } }) => {
      const { dateStart, dateEnd } = original;
      return `${dayjs(dateStart).format('DD.MM.YYYY HH:mm')} - ${dayjs(dateEnd).format('DD.MM.YYYY HH:mm')}`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'count',
    header: 'Количество',
    cell: ({ row: { original } }) => {
      const { count } = original;
      return new Intl.NumberFormat().format(count);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'cash',
    header: 'Потрачено шопа',
    cell: ({ row: { original } }) => {
      const { cash, count } = original;
      return `${new Intl.NumberFormat().format(cash * count)} (${new Intl.NumberFormat().format(cash)} за 1 шт.)`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  }, 
  {
    id: 'action',
    cell: ({ row: { original } }) => {
      const { callback } = original;
      return (
        <Button
          variant="phoenix-secondary"
          className="px-3"
          onClick={callback}
        >
          <FontAwesomeIcon
            icon={faPlus}
            transform="down-3"
            className="text-primary"
          />
        </Button>
      );
    },
    meta: {
      headerProps: { style: { width: 60 } },
      cellProps: { className: 'text-end' }
    }
  }
];

const promoCodeStatsTablecolumns2: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'login',
    header: 'Логин',
    cell: ({ row: { original } }) => {
      const { login } = original;
      return login;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'date',
    header: 'Дата покупки',
    cell: ({ row: { original } }) => {
      const { date } = original;
      return `${dayjs(date).format('DD.MM.YYYY HH:mm')}`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  }
];

const CashShop = () => {
  let [itemsList, setItemsList] = useState<any[]>([]);
  let [catalogList, setCatalogList] = useState<any[]>([]);
  let [purchasesList, setPurchasesList] = useState<any[]>([]);


  let [img, setImg] = useState<any>(null);

  const [productsTableData, setProductsTableData] = useState<ProductsTableProductType[]>([]);
  const [productsTableData2, setProductsTableData2] = useState<ProductsTableProductType[]>([]);

  const [filterDateMin, setFilterDateMin] = useState<string>(dayjs().format('DD.MM.YYYY'));
  let filterDateStart: any = undefined;
  let filterDateEnd: any = undefined;

  const [openAddModal, setOpenAddModal] = useState(false);

  let [productsTableData2Name, setProductsTableData2Name] = useState<any>("");

  const table = useAdvanceTable({
    data: productsTableData,
    columns: promoCodeStatsTablecolumns,
    pageSize: 100,
    pagination: true,
    sortable: true,
    initialState: {
      sorting: [
        {
          id: 'date',
          desc: true
        }
      ]
    }
  });

  const table2 = useAdvanceTable({
    data: productsTableData2,
    columns: promoCodeStatsTablecolumns2,
    pageSize: 100,
    pagination: true,
    sortable: true,
    initialState: {
      sorting: [
        {
          id: 'date',
          desc: true
        }
      ]
    }
  });

  const testOpenModal = async (data) => {
    setTableData2(data);
    setProductsTableData2Name(data.catalogData.a_ctname);
    setOpenAddModal(true);
  };

  const setTableData = async () => {

    let dateStart = filterDateStart;
    let dateEnd = filterDateEnd;

    const array = [];

    for (let i = 0; i < purchasesList.length; i++) {
      let el = purchasesList[i];
      
      if (filterDateStart != undefined && filterDateEnd != undefined) {

        if (!el.a_pdate.isBetween(dateStart, dateEnd, 'day', '[]')) {
          continue;
        }
      }

      let elFind = array.find(el2 => el2.a_ctid == el.a_ctid);

      if (elFind == undefined) {

        let items = [];


        if (el.catalogData && el.catalogData.items) {
          for (let i2 = 0; i2 < el.catalogData.items.length; i2++) {
            let item = itemsList.find(el2 => el2.a_index == el.catalogData.items[i2].a_item_idx);



            if (item) {
              item.img = await getIcon(IconType.Item, item.a_texture_id, item.a_texture_row, item.a_texture_col);
              items.push(item);
            }
          }
        } 

        array.push({
          a_ctid: el.a_ctid,
          a_ctname: el.catalogData ? el.catalogData.a_ctname : '',
          count: 1,
          cash: el.catalogData ? el.catalogData.a_cash : 0,
          dateStart: el.a_pdate,
          dateEnd: el.a_pdate,
          items: items,
          callback: () => testOpenModal(el),
        });
      }
      else {
        elFind.count += 1;

        if (el.a_pdate.isBefore(elFind.dateStart, 'second')) {
          elFind.dateStart = el.a_pdate;
        }

        if (el.a_pdate.isAfter(elFind.dateEnd, 'second')) {
          elFind.dateEnd = el.a_pdate;
        }
      }
    }

    setProductsTableData(array);
  };

  const setTableData2 = async (data) => {

    console.log(`setTableData2`, data);

    let dateStart = filterDateStart;
    let dateEnd = filterDateEnd;

    const array = [];

    for (let i = 0; i < purchasesList.length; i++) {
      let el = purchasesList[i];

      if (el.a_ctid != data.a_ctid) {
        continue;
      }
      
      if (filterDateStart != undefined && filterDateEnd != undefined) {
        if (!el.a_pdate.isBetween(dateStart, dateEnd, 'day', '[]')) {
          continue;
        }
      }

      array.push({
        login: el.a_user_idx,
        date: el.a_pdate,
      });
    }

    setProductsTableData2(array);
  };



const getApiData = async () => {
  const t0 = performance.now();
  console.log('[getApiData] START');

  // 1) Запускаем все запросы параллельно
  const t1 = performance.now();
  let result = $api.get('/data/items/list');
  let result2 = $api.get('/data/catalog/list');
  let result3 = $api.get('/cashshop/purchases/list');
  const t2 = performance.now();
  console.log(`[getApiData] Запуск промисов: ${(t2 - t1).toFixed(2)} ms`);

  // 2) Ждём все результаты
  const t3 = performance.now();
  let resultAll = await Promise.all([result, result2, result3]);
  const t4 = performance.now();
  console.log(`[getApiData] Ожидание Promise.all: ${(t4 - t3).toFixed(2)} ms`);

  // 3) Разбираем результаты
  const [itemsRes, catalogRes, purchasesRes] = resultAll;
  itemsList = itemsRes.data;
  catalogList = catalogRes.data;
  purchasesList = purchasesRes.data;
  setItemsList(itemsList);
  setCatalogList(catalogList);
  setPurchasesList(purchasesList);
  const t5 = performance.now();
  console.log(`[getApiData] Распаковка и setState: ${(t5 - t4).toFixed(2)} ms`);

  // 4) Приводим a_cash к number
  for (let i = 0; i < catalogList.length; i++) {
    catalogList[i].a_cash = parseInt(catalogList[i].a_cash);
  }
  const t6 = performance.now();
  console.log(`[getApiData] parseInt a_cash: ${(t6 - t5).toFixed(2)} ms`);

  // 5) Поиск минимальной даты + конвертация a_pdate
  let minData = dayjs();
  for (let i = 0; i < purchasesList.length; i++) {
    let el = purchasesList[i];
    
    el.a_pdate = dayjs(el.a_pdate.replace('Z', ''));
    el.catalogData = catalogList.find(el2 => el2.a_ctid == el.a_ctid);

    if (dayjs(el.a_pdate).diff(minData, 'day') < 0) {
      minData = dayjs(el.a_pdate);
    }
  }
  const t7 = performance.now();
  console.log(`[getApiData] Обработка purchases: ${(t7 - t6).toFixed(2)} ms`);

  // 6) Устанавливаем минимальную дату
  setFilterDateMin(dayjs(minData).format('DD.MM.YYYY'));
  const t8 = performance.now();
  console.log(`[getApiData] setFilterDateMin: ${(t8 - t7).toFixed(2)} ms`);

  // 7) Обновляем таблицу
  setTableData();
  const t9 = performance.now();
  console.log(`[getApiData] setTableData: ${(t9 - t8).toFixed(2)} ms`);

  // Итог
  console.log(`[getApiData] TOTAL: ${(t9 - t0).toFixed(2)} ms`);
};


  const getApiData2 = async () => {
    let result = $api.get('/data/items/list');
    let result2 = $api.get('/data/catalog/list');
    let result3 = $api.get('/cashshop/purchases/list');

    let resultAll = await Promise.all([result, result2, result3]);

    itemsList = resultAll[0].data;
    setItemsList(itemsList);

    catalogList = resultAll[1].data;
    setCatalogList(catalogList);

    for (let i = 0; i < catalogList.length; i++) {
      catalogList[i].a_cash = parseInt(catalogList[i].a_cash);
    }

    purchasesList = resultAll[2].data;
    setPurchasesList(purchasesList);

    let minData = dayjs();

    for (let i = 0; i < purchasesList.length; i++) {
      let el = purchasesList[i];
      
      el.a_pdate = dayjs(el.a_pdate.replace('Z', ''));
      el.catalogData = catalogList.find(el2 => el2.a_ctid == el.a_ctid);

      if (dayjs(el.a_pdate).diff(minData, 'day') < 0) {
        minData = dayjs(el.a_pdate);
      }
    }

    setFilterDateMin(dayjs(minData).format('DD.MM.YYYY'));

    setTableData();
  };

  useEffect(() => {
    getApiData();
  }, []);

  const dataPickerChange = (dates) => {
    const [start, end] = dates;
    filterDateStart = start;
    filterDateEnd = end;

    setTableData();
  };

  
  

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Покупки в игровом магазине</h2>
        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox placeholder="Поиск..." onChange={({ target }) => { table.setGlobalFilter(target.value || undefined); }} />

              <DatePicker
                options={{
                  mode: "range",
                  minDate: filterDateMin,
                  dateFormat: "d.m.Y",
                }}
                onChange={dataPickerChange}
                placeholder="Выберите даты"
              />


            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <div>
              <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
              <AdvanceTableFooter pagination />
            </div>
          </div>
        </AdvanceTableProvider>




        <Modal show={openAddModal} fullscreen className="p-0" centered>
          <Modal.Header>
            <Modal.Title>Подробная информация ({productsTableData2Name})</Modal.Title>
            <Button
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setOpenAddModal(false)}
            />

            <Button
              variant="phoenix-secondary"
              className="px-3"
              onClick={() => setOpenAddModal(false)}
            >
              <FontAwesomeIcon
                icon={faClose}
                transform="down-3"
                className="text-primary"
              />
            </Button>
          </Modal.Header>


          
          <Modal.Body className="pt-4 pb-2 px-4">
            



          <AdvanceTableProvider {...table2}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox placeholder="Поиск..." onChange={({ target }) => { table2.setGlobalFilter(target.value || undefined); }} />

              <DatePicker
                options={{
                  mode: "range",
                  minDate: filterDateMin,
                  dateFormat: "d.m.Y",
                }}
                onChange={dataPickerChange}
                placeholder="Выберите даты"
              />


            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <div>
              <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
              <AdvanceTableFooter pagination />
            </div>
          </div>
        </AdvanceTableProvider>


          </Modal.Body>
        </Modal>


      </div>
    </div>
  );
};

export default CashShop;
