import { faFileExport, faPlus, faFilter,
faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo
   } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { defaultBreadcrumbItems } from 'data/commonData';
import { ProductsTableProductType } from 'data/e-commerce/products';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import product1 from 'assets/img/products/1.png';
import $api from "http";
const dayjs = require('dayjs')
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import EcomTopRegionsTable from 'components/tables/EcomTopRegionsTable';

import india from 'assets/img/country/india.png';

import { ColumnDef, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { TopRegionsTableDataType } from 'data/TopRegionsTableData';
import { Card, Table, Col, Row, Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/base/AdvanceTable';
import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import FeatherIcon from 'feather-icons-react';
import team1 from 'assets/img/team/32.webp';
import team2 from 'assets/img/team/33.webp';
import team3 from 'assets/img/team/34.webp';
import team4 from 'assets/img/team/35.webp';
import team5 from 'assets/img/team/24.webp';
import team6 from 'assets/img/team/29.webp';
import team7 from 'assets/img/team/65.webp';
import team8 from 'assets/img/team/63.webp';
import team9 from 'assets/img/team/31.webp';
import team10 from 'assets/img/team/26.webp';
import team11 from 'assets/img/team/73.webp';
import team12 from 'assets/img/team/75.webp';
import { BadgeBg } from 'components/base/Badge';

import { CSSProperties } from 'react';
import { Form, Nav, Tab } from 'react-bootstrap';
import ReactSelect from 'components/base/ReactSelect';
import { faCheck, faRotate } from '@fortawesome/free-solid-svg-icons';

import { currencyFormat } from 'helpers/utils';
import StarCheckbox from 'components/base/StarCheckbox';

import { useCookies } from 'react-cookie';







export interface DealDetailsInfoType {
  id: number;
  title: string;
  value: string;
  icon: string;
  color: string;
}


import toast from 'react-hot-toast';


//import TelegramLoginButton from 'react-telegram-login';


export interface Stat {
  id: number;
  title: string;
  value: string;
  icon: string;
  color: string;
}

interface DimensionsCardProps {
  stats: Stat[];
  className?: string;
}

const DealsPrintingDimensionsCard = ({
  stats,
  className
}: DimensionsCardProps) => {
  return (
    <Card className={className}>
      <Card.Body>
        <Row className="g-4 g-xl-1 g-xxl-3 justify-content-between">
          {stats.map((stat, index) => (
            <Col key={stat.id} sm="auto">
              <div
                className={classNames(
                  'd-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center',
                  { 'border-start-sm ps-sm-5': index !== 0 }
                )}
              >
                <div
                  className={`d-flex bg-${stat.color}-100 rounded flex-center me-3 mb-sm-3 mb-md-0 mb-xl-3 mb-xxl-0`}
                  style={{ width: '32px', height: '32px' }}
                >
                  <FeatherIcon
                    icon={stat.icon}
                    className={`text-${stat.color}-600 dark__text-${stat.color}-300`}
                  />
                </div>
                <div>
                  <p className="fw-bold mb-1">{stat.title}</p>
                  <h4 className="fw-bolder text-nowrap">{new Intl.NumberFormat().format(parseInt(stat.value))}</h4>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};




interface DealDetailsInfoProps {
  data: DealDetailsInfoType[][];
  className?: string;
}

const DealDetailsInfo = ({ data, className, callback }: DealDetailsInfoProps) => {
  return (
    <div className={classNames('px-xl-4', className)}>
      <Row className="mx-0 mx-sm-3 mx-lg-0 px-lg-0">
        {data.map((category, index) => (
          <Col
            key={index}
            sm={12}
            xxl={6}
            className={classNames('py-3', {
              'col-sm-12 col-xxl-6 border-bottom border-end-xxl': index === 0,
              'border-bottom': index === 1,
              'border-end-xxl border-bottom border-bottom-xxl-0 py-3':
                index === 2
            })}
          >
            <InfoCategory category={category} callback={callback} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

const InfoCategory = ({ category, callback }: { category: DealDetailsInfoType[] }) => {
  return (
    <Table borderless className="w-100 table-stats mb-0">
      <thead>
        <tr>
          <th className="p-0"></th>
          <th className="p-0"></th>
          <th className="p-0"></th>
        </tr>
      </thead>
      <tbody>
        {category.map((item, index) => (
          <InfoItem key={item.id} data={item} index={index} callback={callback} />
        ))}
      </tbody>
    </Table>
  );
};

const InfoItem = ({
  data,
  index,
  callback
}: {
  data: DealDetailsInfoType;
  index: number;
}) => {
  const [amount, setAmount] = useState<Number>(0);
  const [comment, setComment] = useState('');

  const sendData = async () => {
    if (amount < 1) {
      return toast.error('Сумма не может быть меньше 1.');
    }

    await callback(data.system, data.id, amount, comment);

    setAmount(0);
    setComment('');
  };

  return (
    <>
    <tr>
      <td className="py-2 lh-1">
        <div
          className={classNames('d-inline-flex align-items-center p-0', {
            'd-flex': index == 1
          })}
        >
          <div
            className={`d-flex bg-${data.color}-100 rounded-circle flex-center me-3`}
            style={{ width: '24px', height: '24px' }}
          >
            <FeatherIcon
              icon={data.icon}
              className={`text-${data.color}-600 dark__text-${data.color}-300`}
              width={16}
              height={16}
            />
          </div>
          <p className="fw-bold mb-0">{data.title}:&nbsp;</p>
          <p
            className={classNames('ps-6 ps-sm-0 fw-semi-bold mb-0 py-0 pe-0', {
              'pb-3 pb-sm-0': index === 0
            })}
          >
            {new Intl.NumberFormat().format(parseInt(data.value))}
          </p>
        </div>
      </td>
      <td className="py-2 d-none d-sm-block pe-sm-2">:</td>
      <td className="py-2">
        
          
        
      </td>
    </tr>
    <tr>
      <td className="py-2 lh-1" colSpan="3">
        <Form.Control
          type="number"
          placeholder="Сумма"
          value={amount}
          onChange={({ target }) => {
            setAmount(target.value);
          }}
        />
      </td>
    </tr>
    <tr>
      <td className="py-2 lh-1" colSpan="3">
        <Form.Control
          type="text"
          placeholder="Комментарий (необяз.)"
          value={comment}
          onChange={({ target }) => {
            setComment(target.value);
          }}
        />
      </td>
    </tr>
    <tr>
      <td className="py-2 lh-1" colSpan="3">
        <Button variant="primary" onClick={sendData}>
          Отправить
        </Button>
      </td>
    </tr>
    </>
  );
};

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var weekOfYear = require('dayjs/plugin/weekOfYear')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(weekOfYear)

dayjs.tz.setDefault("Europe/Moscow")

export const promoCodeStatsTablecolumns: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_date',
    header: 'Дата',
    cell: ({ row: { original } }) => {
      const { a_date } = original;
      return dayjs(a_date).utc().format('DD.MM.YYYY HH:mm:ss');
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'a_login',
    header: 'Аккаунт',
    cell: ({ row: { original } }) => {
      const { a_login } = original;
      return a_login;
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'a_system',
    header: 'Платёжная система',
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_cash',
    header: 'Сумма (шоп)',
    cell: ({ row: { original } }) => {
      const { a_cash } = original;
      return new Intl.NumberFormat().format(a_cash);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_sum',
    header: 'Сумма (руб.)',
    cell: ({ row: { original } }) => {
      const { a_sum } = original;
      return `${new Intl.NumberFormat().format(a_sum)} (${new Intl.NumberFormat().format(parseInt(a_sum * 0.3))})`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
];

export const promoCodeStatsTablecolumns2: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_date',
    header: 'Дата',
    cell: ({ row: { original } }) => {
      const { a_date } = original;
      return dayjs(a_date).utc().format('DD.MM.YYYY HH:mm:ss');
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'a_system',
    header: 'Платёжная система',
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_cash',
    header: 'Сумма (шоп)',
    cell: ({ row: { original } }) => {
      const { a_cash } = original;
      return new Intl.NumberFormat().format(a_cash);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_sum',
    header: 'Сумма (руб.)',
    cell: ({ row: { original } }) => {
      const { a_sum } = original;
      return `${new Intl.NumberFormat().format(a_sum)} (${new Intl.NumberFormat().format(parseInt(a_sum * 0.3))})`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
];

export const promoCodeStatsTablecolumns3: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_date',
    header: 'Дата',
    cell: ({ row: { original } }) => {
      const { a_date } = original;
      return dayjs(a_date).utc().format('DD.MM.YYYY');
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'a_cash',
    header: 'Сумма (шоп)',
    cell: ({ row: { original } }) => {
      const { a_cash } = original;
      return new Intl.NumberFormat().format(a_cash);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_sum',
    header: 'Сумма (руб.)',
    cell: ({ row: { original } }) => {
      const { a_sum } = original;
      return `${new Intl.NumberFormat().format(a_sum)} (${new Intl.NumberFormat().format(parseInt(a_sum * 0.3))})`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
];

export const promoCodeStatsTablecolumns4: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_date',
    header: 'Дата',
    cell: ({ row: { original } }) => {
      const { a_date } = original;
      return dayjs(a_date).utc().format(`${`${dayjs(a_date).utc().week()}`.padStart(2, '0')}.YYYY`);
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'a_cash',
    header: 'Сумма (шоп)',
    cell: ({ row: { original } }) => {
      const { a_cash } = original;
      return new Intl.NumberFormat().format(a_cash);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_sum',
    header: 'Сумма (руб.)',
    cell: ({ row: { original } }) => {
      const { a_sum } = original;
      return `${new Intl.NumberFormat().format(a_sum)} (${new Intl.NumberFormat().format(parseInt(a_sum * 0.3))})`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
];

export const promoCodeStatsTablecolumns5: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_date',
    header: 'Дата',
    cell: ({ row: { original } }) => {
      const { a_date } = original;
      return dayjs(a_date).utc().format('MM.YYYY');
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'a_cash',
    header: 'Сумма (шоп)',
    cell: ({ row: { original } }) => {
      const { a_cash } = original;
      return new Intl.NumberFormat().format(a_cash);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_sum',
    header: 'Сумма (руб.)',
    cell: ({ row: { original } }) => {
      const { a_sum } = original;
      return `${new Intl.NumberFormat().format(a_sum)} (${new Intl.NumberFormat().format(parseInt(a_sum * 0.3))})`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
];

export const promoCodeStatsTablecolumns6: ColumnDef<ProductsTableProductType>[] = [
  {
    accessorKey: 'a_date',
    header: 'Дата',
    cell: ({ row: { original } }) => {
      const { a_date } = original;
      return dayjs(a_date).utc().format('MM.YYYY');
    },
    meta: {
      headerProps: { style: { width: 80 }, className: 'ps-4' },
      cellProps: { className: 'text-600 ps-4' }
    }
  },
  {
    accessorKey: 'a_system',
    header: 'Платёжная система',
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_cash',
    header: 'Сумма (шоп)',
    cell: ({ row: { original } }) => {
      const { a_cash } = original;
      return new Intl.NumberFormat().format(a_cash);
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
  {
    accessorKey: 'a_sum',
    header: 'Сумма (руб.)',
    cell: ({ row: { original } }) => {
      const { a_sum } = original;
      return `${new Intl.NumberFormat().format(a_sum)} (${new Intl.NumberFormat().format(parseInt(a_sum * 0.3))})`;
    },
    meta: {
      headerProps: { style: { width: 150 }, className: 'ps-4' },
      cellProps: { className: 'fs-9 fw-semi-bold ps-4 text-600' }
    }
  },
];






type NavItemType = {
  label: string;
  icon: string;
  eventKey: string;
};

const NavItem = ({ item, isLast }: { item: NavItemType; isLast?: boolean }) => {
  return (
    <Nav.Link
      eventKey={item.eventKey}
      className={classNames(
        'text-center text-sm-start cursor-pointer outline-none d-sm-flex align-items-sm-center',
        {
          'border-bottom-sm border-end border-end-sm-0 border-300': !isLast
        }
      )}
    >
      <FeatherIcon icon={item.icon} size={16} className="me-sm-2 nav-icons" />
      <span className="d-none d-sm-inline">{item.label}</span>
    </Nav.Link>
  );
};

const navItems: NavItemType[] = [
  {
    label: 'Пополнения',
    icon: faChartLine,
    eventKey: 'pay'
  },
  {
    label: 'Выплаты',
    icon: faChartLine,
    eventKey: 'payout'   
  },
  {
    label: 'Графики',
    icon: faChartLine,
    eventKey: 'charts'   
  },
];

const navItems2: NavItemType[] = [
  {
    label: 'По платежам',
    icon: faChartLine,
    eventKey: 'payments'
  },
  {
    label: 'По кассам',
     icon: faChartLine,
    eventKey: 'pricing'   
  },
  {
    label: 'По дням',
    icon: faChartLine,
    eventKey: 'shipping'
  },
  {
    label: 'По неделям',
    icon: faChartLine,
    eventKey: 'global-delivery'
  },
  {
    label: 'По месяцам',
    icon: faChartLine,
    eventKey: 'attributes'
  },
  {
    label: 'По месяцам (кассы)',
    icon: faChartLine,
    eventKey: 'advanced'
  }
];

const navItems3: NavItemType[] = [
  {
    label: 'Lava',
    icon: faChartLine,
    eventKey: 'lava'
  },
  {
    label: 'Cent.App',
    icon: faChartLine,
    eventKey: 'centapp'
  }
];


















const Finance = () => {
  let data: any[] = [];
  const [productsTableData, setProductsTableData] = useState<ProductsTableProductType[]>([]);
  const [productsTableData2, setProductsTableData2] = useState<ProductsTableProductType[]>([]);
  const [productsTableData3, setProductsTableData3] = useState<ProductsTableProductType[]>([]);
  const [productsTableData4, setProductsTableData4] = useState<ProductsTableProductType[]>([]);
  const [productsTableData5, setProductsTableData5] = useState<ProductsTableProductType[]>([]);
  const [productsTableData6, setProductsTableData6] = useState<ProductsTableProductType[]>([]);
  let [payoutData, setPayoutData] = useState<any>({});

  const table = useAdvanceTable({
    data: productsTableData,
    columns: promoCodeStatsTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const table2 = useAdvanceTable({
    data: productsTableData2,
    columns: promoCodeStatsTablecolumns2,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const table3 = useAdvanceTable({
    data: productsTableData3,
    columns: promoCodeStatsTablecolumns3,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const table4 = useAdvanceTable({
    data: productsTableData4,
    columns: promoCodeStatsTablecolumns4,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const table5 = useAdvanceTable({
    data: productsTableData5,
    columns: promoCodeStatsTablecolumns5,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const table6 = useAdvanceTable({
    data: productsTableData6,
    columns: promoCodeStatsTablecolumns6,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const setTableData = async () => {
    const array = [];
    const systemArray = [];
    const daysArray = [];
    const weeksArray = [];
    const monthsArray = [];
    const monthsArray2 = [];

    const info = {};

    //console.log('------- start ----------');

    for (let i = 0; i < data.pay.length; i++) {
      let payData = data.pay[i];
      payData.a_cash = parseInt(payData.a_cash);
      payData.a_sum = parseFloat(payData.a_sum);

      let payJsonData = JSON.parse(payData.a_data);

      if (payData.a_system == 'stripe') {
        payData.a_sum *= 85;
      }
      else if (payData.a_system == 'paypal') {
        payData.a_sum *= 75;
      }
      else if (payData.a_system == 'lava' || payData.a_system == 'cent.app' || payData.a_system == 'boosty') {
        payData.a_sum = parseFloat(payJsonData.credited);
      }
      else if (payData.a_system == 'yandex') {
        payData.a_sum = parseFloat(payJsonData.sum_credited);
      }

      //if (payData.a_system == 'lava') {
        if (info[payData.a_system] == undefined) {
          info[payData.a_system] = {
            total: payData.a_sum,
            totalFixed: payData.a_sum
          };
        }
        else {
          info[payData.a_system].total += payData.a_sum;
          info[payData.a_system].totalFixed += payData.a_sum;
        }
      //}
      
      array.push(payData);

      let date = dayjs(payData.a_date).utc().format('DD.MM.YYYY');

      // systems
      let systemFind = systemArray.find(el => el.dateForFind == date && el.a_system == payData.a_system);

      if (systemFind == undefined) {
        systemArray.push({
          dateForFind: date,
          a_date: payData.a_date,
          a_system: payData.a_system,
          a_cash: payData.a_cash,
          a_sum: payData.a_sum,
        });
      }
      else {
        systemFind.a_cash += payData.a_cash;
        systemFind.a_sum += payData.a_sum;
      }
      // /systems

      // days
      let dayFind = daysArray.find(el => el.dateForFind == date);

      if (dayFind == undefined) {
        daysArray.push({
          dateForFind: date,
          a_date: payData.a_date,
          a_cash: payData.a_cash,
          a_sum: payData.a_sum,
        });
      }
      else {
        dayFind.a_cash += payData.a_cash;
        dayFind.a_sum += payData.a_sum;
      }
      // /days

      // weeks
      date = dayjs(payData.a_date).utc().format(`${dayjs(payData.a_date).utc().week()}.YYYY`);
      
      let weekFind = weeksArray.find(el => el.dateForFind == date);

      if (weekFind == undefined) {
        weeksArray.push({
          dateForFind: date,
          a_date: payData.a_date,
          a_cash: payData.a_cash,
          a_sum: payData.a_sum,
        });
      }
      else {
        weekFind.a_cash += payData.a_cash;
        weekFind.a_sum += payData.a_sum;
      }
      // /weeks

      // months
      date = dayjs(payData.a_date).utc().format('MM.YYYY');
      
      let monthFind = monthsArray.find(el => el.dateForFind == date);

      if (monthFind == undefined) {
        monthsArray.push({
          dateForFind: date,
          a_date: payData.a_date,
          a_cash: payData.a_cash,
          a_sum: payData.a_sum,
        });
      }
      else {
        monthFind.a_cash += payData.a_cash;
        monthFind.a_sum += payData.a_sum;
      }
      // /months

      // months2
      date = dayjs(payData.a_date).utc().format('MM.YYYY');
      
      let monthFind2 = monthsArray2.find(el => el.dateForFind == date && el.a_system == payData.a_system);

      if (monthFind2 == undefined) {
        monthsArray2.push({
          dateForFind: date,
          a_date: payData.a_date,
          a_system: payData.a_system,
          a_cash: payData.a_cash,
          a_sum: payData.a_sum,
        });
      }
      else {
        monthFind2.a_cash += payData.a_cash;
        monthFind2.a_sum += payData.a_sum;
      }
      // /months2
    }

    //console.log('------- end ----------');

    setProductsTableData(array);
    setProductsTableData2(systemArray);
    setProductsTableData3(daysArray);
    setProductsTableData4(weeksArray);
    setProductsTableData5(monthsArray);
    setProductsTableData6(monthsArray2);

    let infoPayout = {};

    for (let i = 0; i < data.payout.length; i++) {
      let payoutData = data.payout[i];
      payoutData.a_sum = parseFloat(payoutData.a_sum);

      if (info[payoutData.a_system] != undefined) {
        info[payoutData.a_system].total -= payoutData.a_sum;
      }


      if (infoPayout[payoutData.a_system] == undefined) {
        infoPayout[payoutData.a_system] = {
          total: {}
        };

        infoPayout[payoutData.a_system].total[payoutData.a_type] = payoutData.a_sum;
      }
      else {
        if (infoPayout[payoutData.a_system].total[payoutData.a_type] == undefined) {
          infoPayout[payoutData.a_system].total[payoutData.a_type] = payoutData.a_sum;
        }
        else {
          infoPayout[payoutData.a_system].total[payoutData.a_type] += payoutData.a_sum;
        }
      }
    }

    //payoutData.lava.balance.current[0].value = 500;

    //setPayoutData(payoutData);

    setPayoutData({
      lava: {
        balance: {
          current: [
            {
              id: 1,
              title: 'Текущий баланс',
              value: info.lava.total,
              icon: 'dollar-sign',
              color: 'success'
            }
          ],
          total: [
            {
              id: 1,
              title: 'Общий баланс',
              value: info.lava.totalFixed,
              icon: 'dollar-sign',
              color: 'warning'
            }
          ]
        },
        info: [
          [
            {
              id: 0,
              title: 'Сервер',
              value: info.lava.totalFixed * 0.1 - infoPayout.lava.total[0],
              icon: 'bar-chart-2',
              color: 'secondary',
              system: 'lava'
            }
          ],
          [
            {
              id: 2,
              title: 'Костя',
              value: info.lava.totalFixed * 0.3 - infoPayout.lava.total[2],
              icon: 'bar-chart-2',
              color: 'primary',
              system: 'lava'
            }
          ],
          [
            {
              id: 1,
              title: 'Егор',
              value: info.lava.totalFixed * 0.3 - infoPayout.lava.total[1],
              icon: 'bar-chart-2',
              color: 'success',
              system: 'lava'
            }
          ],
          [
            {
              id: 3,
              title: 'Саша',
              value: info.lava.totalFixed * 0.3 - infoPayout.lava.total[3],
              icon: 'bar-chart-2',
              color: 'danger',
              system: 'lava'
            }
          ]
        ]
      },
      centapp: {
        balance: {
          current: [
            {
              id: 1,
              title: 'Текущий баланс',
              value: info['cent.app'].total,
              icon: 'dollar-sign',
              color: 'success'
            }
          ],
          total: [
            {
              id: 1,
              title: 'Общий баланс',
              value: info['cent.app'].totalFixed,
              icon: 'dollar-sign',
              color: 'warning'
            }
          ]
        },
        info: [
          [
            {
              id: 0,
              title: 'Сервер',
              value: info['cent.app'].totalFixed * 0.1 - infoPayout['cent.app'].total[0],
              icon: 'bar-chart-2',
              color: 'secondary',
              system: 'centapp'
            }
          ],
          [
            {
              id: 2,
              title: 'Костя',
              value: info['cent.app'].totalFixed * 0.3 - infoPayout['cent.app'].total[2],
              icon: 'bar-chart-2',
              color: 'primary',
              system: 'centapp'
            }
          ],
          [
            {
              id: 1,
              title: 'Егор',
              value: info['cent.app'].totalFixed * 0.3 - infoPayout['cent.app'].total[1],
              icon: 'bar-chart-2',
              color: 'success',
              system: 'centapp'
            }
          ],
          [
            {
              id: 3,
              title: 'Саша',
              value: info['cent.app'].totalFixed * 0.3 - infoPayout['cent.app'].total[3],
              icon: 'bar-chart-2',
              color: 'danger',
              system: 'centapp'
            }
          ]
        ]
      }
    });
  };

  const getApiData = async () => {
    let result = await $api.get('/finance/data');
    data = result.data;
    setTableData();
  };  

  const sendApiData = async (system, type, amount, comment) => {
    console.log(`sendApiData: ${system}, ${type}, ${amount}, ${comment}}`);

    let result = await $api.post('/finance/writePayout', { system, type, amount, comment });

    //data = result.data;
    //setTableData();

    console.log(result);

    toast.success('Успешно записано!');
  };

  useEffect(() => {
    getApiData();
  }, []);


  const [cookies, setCookie] = useCookies(['mainTab', 'payTab', 'payoutTab']);

/*
const handleTelegramResponse = response => {
  console.log(response);
};
*/

// <TelegramLoginButton dataOnauth={handleTelegramResponse} botName="HardLCBot" />,
  
  return (
    <div>


    

    
      <div className="mb-9">
        <h2 className="mb-4">Финансы</h2>
        <Tab.Container defaultActiveKey={cookies.mainTab ? cookies.mainTab : "pay"} onSelect=
          {(eventKey: string | null) => {
            if (eventKey) {
              setCookie('mainTab', eventKey);
            }
          }}>
          <Nav
            variant="underline"
            className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-6"
          >
          {navItems.map((item, index) => (
            <Nav.Item key={item.eventKey} className="me-2 text-nowrap">
              <Nav.Link eventKey={item.eventKey.toLowerCase()}>
                <FontAwesomeIcon
                  icon={item.icon}
                  className="me-2 tab-icon-color"
                />
                {item.label}
              </Nav.Link>
            </Nav.Item>
          ))}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="pay">
              <Tab.Container defaultActiveKey={cookies.payTab ? cookies.payTab : "payments"} onSelect=
                {(eventKey: string | null) => {
                  if (eventKey) {
                    setCookie('payTab', eventKey);
                  }
                }}>
                <Nav
                  variant="underline"
                  className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-6"
                >
                {navItems2.map((item, index) => (
                  <Nav.Item key={item.eventKey} className="me-2 text-nowrap">
                    <Nav.Link eventKey={item.eventKey.toLowerCase()}>
                      <FontAwesomeIcon
                        icon={item.icon}
                        className="me-2 tab-icon-color"
                      />
                      {item.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="payments">
                    <AdvanceTableProvider {...table}>
                      <div className="mb-4">
                        <div className="d-flex flex-wrap gap-3">
                          <SearchBox placeholder="Поиск..." onChange={({ target }) => { table.setGlobalFilter(target.value || undefined); }} />
                        </div>
                      </div>
                      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                        <div>
                          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                          <AdvanceTableFooter pagination />
                        </div>
                      </div>
                    </AdvanceTableProvider>
                  </Tab.Pane>
                  <Tab.Pane eventKey="pricing">
                    <AdvanceTableProvider {...table2}>
                      <div className="mb-4">
                        <div className="d-flex flex-wrap gap-3">
                          <SearchBox placeholder="Поиск..." onChange={({ target }) => { table2.setGlobalFilter(target.value || undefined); }} />
                        </div>
                      </div>
                      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                        <div>
                          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                          <AdvanceTableFooter pagination />
                        </div>
                      </div>
                    </AdvanceTableProvider>
                  </Tab.Pane>
                  <Tab.Pane eventKey="shipping">
                    <AdvanceTableProvider {...table3}>
                      <div className="mb-4">
                        <div className="d-flex flex-wrap gap-3">
                          <SearchBox placeholder="Поиск..." onChange={({ target }) => { table3.setGlobalFilter(target.value || undefined); }} />
                        </div>
                      </div>
                      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                        <div>
                          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                          <AdvanceTableFooter pagination />
                        </div>
                      </div>
                    </AdvanceTableProvider>
                  </Tab.Pane>
                  <Tab.Pane eventKey="global-delivery">
                    <AdvanceTableProvider {...table4}>
                      <div className="mb-4">
                        <div className="d-flex flex-wrap gap-3">
                          <SearchBox placeholder="Поиск..." onChange={({ target }) => { table4.setGlobalFilter(target.value || undefined); }} />
                        </div>
                      </div>
                      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                        <div>
                          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                          <AdvanceTableFooter pagination />
                        </div>
                      </div>
                    </AdvanceTableProvider>
                  </Tab.Pane>
                  <Tab.Pane eventKey="attributes">
                    <AdvanceTableProvider {...table5}>
                      <div className="mb-4">
                        <div className="d-flex flex-wrap gap-3">
                          <SearchBox placeholder="Поиск..." onChange={({ target }) => { table5.setGlobalFilter(target.value || undefined); }} />
                        </div>
                      </div>
                      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                        <div>
                          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                          <AdvanceTableFooter pagination />
                        </div>
                      </div>
                    </AdvanceTableProvider>
                  </Tab.Pane>
                  <Tab.Pane eventKey="advanced">
                    <AdvanceTableProvider {...table6}>
                      <div className="mb-4">
                        <div className="d-flex flex-wrap gap-3">
                          <SearchBox placeholder="Поиск..." onChange={({ target }) => { table6.setGlobalFilter(target.value || undefined); }} />
                        </div>
                      </div>
                      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                        <div>
                          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
                          <AdvanceTableFooter pagination />
                        </div>
                      </div>
                    </AdvanceTableProvider>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Tab.Pane>
            <Tab.Pane eventKey="payout">
              <Tab.Container defaultActiveKey={cookies.payoutTab ? cookies.payoutTab : "lava"} onSelect=
                {(eventKey: string | null) => {
                  if (eventKey) {
                    setCookie('payoutTab', eventKey);
                  }
                }}>
                <Nav
                  variant="underline"
                  className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-6"
                >
                {navItems3.map((item, index) => (
                  <Nav.Item key={item.eventKey} className="me-2 text-nowrap">
                    <Nav.Link eventKey={item.eventKey.toLowerCase()}>
                      <FontAwesomeIcon
                        icon={item.icon}
                        className="me-2 tab-icon-color"
                      />
                      {item.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="lava">
                    {payoutData.lava != undefined && (
                      <>
                      <DealsPrintingDimensionsCard stats={payoutData.lava.balance.current} className="mb-2" />
                      <DealsPrintingDimensionsCard stats={payoutData.lava.balance.total} className="mb-5" />
                      <DealDetailsInfo data={payoutData.lava.info} className="mb-7" callback={sendApiData} />
                      </>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="centapp">
                    {payoutData.centapp != undefined && (
                      <>
                      <DealsPrintingDimensionsCard stats={payoutData.centapp.balance.current} className="mb-2" />
                      <DealsPrintingDimensionsCard stats={payoutData.centapp.balance.total} className="mb-5" />
                      <DealDetailsInfo data={payoutData.centapp.info} className="mb-7" callback={sendApiData} />
                      </>
                    )}
                  </Tab.Pane>
                  </Tab.Content>
              </Tab.Container>
            </Tab.Pane>
            <Tab.Pane eventKey="charts">
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Finance;
